import React from "react"
import { graphql, PageProps } from "gatsby"
import { withAuthentication } from "@app/hoc/Authentication"
import Page from "@app/components/Account/Login/AccountAuth"

export type Props = PageProps<GatsbyTypes.PageAccountLoginLoadingQuery, GatsbyTypes.PageAccountLoginLoadingQueryVariables>

export const query = graphql`
  query PageAccountLoginLoading {
    page: sanityPageAccountLogin {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default withAuthentication(Component)
