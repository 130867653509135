import React, { useState, useEffect } from "react"
import { Container, Text } from "@chakra-ui/react"
import { useCheckoutContext } from "@app/providers/checkout"
import { useCustomerContext } from "@app/providers/customer"
import type { Props } from "@app/pages/account/login/auth"
import type { PageProps } from "@root/types/global"

const AccountAuth: React.FC<PageProps<Props>> = ({ location }) => {
  const LOADING_STATES = ["Verifying Account...", "Loading Customer Details...", "Completing Login..."]
  const [status, setStatus] = useState(LOADING_STATES[0])
  const { customer } = useCustomerContext()
  const { gotoCheckout } = useCheckoutContext()

  useEffect(() => {
    if (location?.state?.checkout && customer?.id) {
      gotoCheckout()
    }
  }, [location, customer, gotoCheckout])

  useEffect(() => {
    setTimeout(() => {
      setStatus(LOADING_STATES[1])
      setTimeout(() => {
        setStatus(LOADING_STATES[2])
      }, 3000)
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Text>{status}</Text>
    </Container>
  )
}

export default AccountAuth
